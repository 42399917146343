import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { AppUser } from '@/models/app-user-dto'
import Commons from '@/components/shared/Helpers/commons'
import { AuthService } from '@/services/auth-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { Subscription } from 'rxjs'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { PrestationModel } from '@/models/prescriptions-model'
import { langues } from '../../../../views/Patients/Dossier/Constants'
import { PatientService } from '@/services/patient-service'
import { DossierAssurance } from '../../../../views/Patients/Static'
import DocPreviewBase from '../DocPreviewBase.vue'
import AddressBloc from '../AddressBloc.vue'

@Component({
  components: {
    DocPreviewBase,
    AddressBloc
  }
})
export default class CourseGroupReport extends Vue {
  @Prop({ default: {} })
  public dossierAssurance!: DossierAssurance

  @Prop({ default: [] })
  public reseauPro!: any[]

  @Prop()
  public datePrestation!: string

  private subscriptions: Subscription[] = []
  private userService = AuthService.getInstance()
  private prescriptionService = PatientPrescriptionService.getInstance()
  private patientService = PatientService.getInstance()
  private selectedDossier: dossierPatientModel| any = {}
  public patientFullName = ''
  public userFullName = ''
  public users: AppUser[] = []
  public medecinId = ''
  public medecin: any = null
  public prestation: PrestationModel | any = {}
  public langues = langues
  public insurance = ''
  public assurances: any[] = []
  public addressBloc: any[] = []
  public materiels: string[] = []
  public diagnostic = ''
  public oralAntidiabeticDrugs = ''
  public insulin = ''
  public otherDrugs = ''
  public specialRequest = ''
  public courseAmbalatoire = false
  public loaded = false

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      this.selectedDossier = JSON.parse(cachedDossier!)
      this.patientFullName = this.selectedDossier.fullName
    } else {
      this.$router.push({ name: 'patient' })
    }
    this.subscriptions.push(this.patientService.assuranceList$.subscribe((assurance: any[]) => {
      this.assurances = assurance
      if (!assurance.length) {
        this.patientService.getAllAssurance()
      }
      this.getDossierAssurance()
    }))
    this.subscriptions.push(this.prescriptionService.prestationSelected$.subscribe(selectedPrestation => {
      this.prestation = selectedPrestation
    }))
    this.userFullName = this.userService.fullName
    this.getDossierAssurance()
  }

  public getDossierAssurance () {
    if (this.selectedDossier?.guid) {
      this.mapDossierAssuranceResponse(this.dossierAssurance)
    }
    this.loaded = true
  }

  private mapDossierAssuranceResponse (dossierResponse: DossierAssurance) {
    this.dossierAssurance = dossierResponse
    this.mapInsuranceName()
  }

  private mapInsuranceName () {
    this.insurance = this.assurances.find(x => x.id === this.dossierAssurance.assuranceId)?.assuranceName
  }

  public doctorChanged (doctorId: string) {
    this.medecin = this.reseauPro.find(x => x.reseauMedicalExtra?.reseauId === doctorId)
    this.$emit('doctorChanged', this.medecin?.fullname)
  }

  get languages () {
    if (this.selectedDossier?.languagesEnumIdList) {
      this.selectedDossier.languagesEnumIdList = Commons.transformLanguages(this.selectedDossier.languagesEnumIdList)
      return this.langues.filter(l => this.selectedDossier.languagesEnumIdList.some(x => Number(x) === Number(l.value))).map(a => a.text).join(", ")
    }
    return ''
  }

  get formattedDiagnostic () {
    return Commons.newlines2br(this.diagnostic)
  }

  get formattedOralAntidiabeticDrugs () {
    return Commons.newlines2br(this.oralAntidiabeticDrugs)
  }

  get formattedInsulin () {
    return Commons.newlines2br(this.insulin)
  }

  get formattedOtherDrugs () {
    return Commons.newlines2br(this.otherDrugs)
  }

  get formattedSpecialRequest () {
    return Commons.newlines2br(this.specialRequest)
  }

  get theDate () {
    return this.datePrestation ? new Date(this.datePrestation) : new Date()
  }
}
