

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '@/components/shared/Helpers/commons'

@Component
export default class AddressBloc extends Vue {
  @Prop() public selectedDossier!: dossierPatientModel
  @Prop() public insurance!: string
  @Prop() public languages!: string

  public addressBloc: any[] = []

  @Watch('insurance')
  public insuranceChanged () {
    this.updateAddressBlock()
  }

  public mounted () {
    this.updateAddressBlock()
  }

  public updateAddressBlock () {
    this.addressBloc = [
      {
        col: 'left',
        rows: [
          {
            label: 'Entête',
            value: this.selectedDossier.salutation
          },
          {
            label: 'Nom, Prénom',
            value: this.selectedDossier.fullName
          },
          {
            label: 'Adresse',
            value: this.selectedDossier.address
          },
          {
            label: 'NPA / Localité',
            value: this.selectedDossier.npa + ' ' + this.selectedDossier.city
          },
          {
            label: 'Caisse maladie',
            value: this.insurance
          }
        ]
      },
      {
        col: 'right',
        rows: [
          {
            label: 'Langue',
            value: this.languages
          },
          {
            label: 'Date de naissance',
            value: this.formattedDob
          },
          {
            label: 'Tél. privé',
            value: this.selectedDossier.telephone_private
          },
          {
            label: 'Mobile',
            value: this.selectedDossier.mobile
          },
          {
            label: 'Nº AVS',
            value: this.selectedDossier.avsNumber
          }
        ]
      }
    ]
  }

  get formattedDob () {
    if (this.selectedDossier?.dob) {
      return Commons.TransformDateFormat(this.selectedDossier.dob)
    }
    return ''
  }
}
