import { Component, Vue } from 'vue-property-decorator'
import DocumentPreview from '@/components/shared/DocumentPreview/CourseGroupReport/CourseGroupReport.vue'
import Commons from '@/components/shared/Helpers/commons'
import { Subscription } from 'rxjs'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PrestationModel } from '@/models/prescriptions-model'
import { ValidationObserver } from 'vee-validate'
import ReportHelpers from '@/components/shared/Helpers/ReportHelpers'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { PatientService } from '@/services/patient-service'
import { DossierAssurance } from '../../Static'

@Component({
  components: {
    Alert,
    DocumentPreview,
    ValidationObserver
  }
})
export default class Report extends Vue {
  private subscriptions: Subscription[] = []
  private prescriptionService = PatientPrescriptionService.getInstance()
  private patientService = PatientService.getInstance()

  private selectedDossier!: dossierPatientModel
  public prestation: PrestationModel | any = {}
  public patientFullName = ''
  public loading = false
  public saving = false
  public errorMessages: string[] = []
  public dossierAssurance: DossierAssurance = {}
  public reseauPro: any[] = []
  public doctorName = ''

  public async mounted () {
    this.loading = true
    this.saving = false
    Commons.updateDossierName()
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      this.selectedDossier = JSON.parse(cachedDossier)
      this.patientFullName = this.selectedDossier.fullName
    }
    this.subscriptions.push(this.prescriptionService.prestationSelected$.subscribe(selectedPrestation => {
      this.prestation = selectedPrestation
    }))
    await Commons.getDossierReseau(this.selectedDossier.guid, this.errorMessages, this.reseauPro)
    await this.getDossierAssurance()
    this.loading = false
  }

  public async getDossierAssurance () {
    if (this.selectedDossier?.guid) {
      await this.patientService.getDossierAssuranceInfo(this.selectedDossier.guid).then(data => {
        this.dossierAssurance = data
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
    }
  }

  public async savePDF () {
    this.saving = true
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      const documentPreview = this.$refs.documentpreview as any
      const model = ReportHelpers.GeneratePDFModel(6, `Rapport cours et groups-${this.patientFullName}-${new Date().toLocaleDateString()}.pdf`, documentPreview,
        this.prestation.prescriptionId, this.doctorName, undefined)
      if (this.selectedDossier.guid) {
        await ReportHelpers.UploadDocument(model, this.errorMessages, this.$router, this.selectedDossier.guid)
      }
    } else {
      const merged = Object.assign(this.$refs, (this.$refs.documentpreview as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
    this.saving = false
  }

  public goBack () {
    this.$router.push({ name: 'PatientPrescriptions' })
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public get datePrestation () {
    return this.prestation?.dateStart
  }

  public doctorChanged (doctorName) {
    this.doctorName = doctorName
  }
}
